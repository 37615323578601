import {createReducer, on} from '@ngrx/store';
import {DescriptionActions} from './action-types';
import {createEntityAdapter, EntityAdapter} from "@ngrx/entity";
import {addDescriptions, Description} from "./description.actions";


export interface State {

}

export const adapter: EntityAdapter<Description> = createEntityAdapter<Description>({
});

export const initialState: State = {

};

export const descriptionsReducer = createReducer(
  initialState,

  on(
    DescriptionActions.saveDescription,
    DescriptionActions.addDescription,
    (state, action) => {
      const newState = {};
      newState[action.id] = action.description;

      return {...state, ...newState};
    }),

  on(DescriptionActions.saveDescriptionSynchronizedClones, (state, action) => {
    const description = action.description.description;
    const descriptionId = action.description.id;
    const lessons = action.lessons;
    const courses = action.courses;

    const newState = {};

    lessons.forEach((lesson) => {

      if (lesson?.sourceLessonId === descriptionId) {

        newState[lesson.id] = description;

      }

    });

    courses.forEach((course) => {

      if (course?.sourceCourseId === descriptionId) {

        newState[course.id] = description;

      }

    })

    return {...state, ...newState};

  }),
  on(DescriptionActions.addDescriptions, (state, action) => {

    const descriptions = action.descriptions;

    const newState = {};

    descriptions.forEach(({id, description}) => {
      newState[id] = description
    });

    return {...state, ...newState};

  }),
);



